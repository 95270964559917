import { default as indexVdS9zMbEBMMeta } from "/home/niko/repo/manuscrape_nuxt/pages/index.vue?macro=true";
import { default as loginHqbubZsrtFMeta } from "/home/niko/repo/manuscrape_nuxt/pages/login.vue?macro=true";
import { default as logouta5YsGA69f8Meta } from "/home/niko/repo/manuscrape_nuxt/pages/logout.vue?macro=true";
import { default as draftsD487amNQK2Meta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/drafts.vue?macro=true";
import { default as indexabKndpnNvtMeta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/index.vue?macro=true";
import { default as edit_45imageuZhLx3Hep7Meta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/[observationId]/edit-image.vue?macro=true";
import { default as indexVXK3ElNjzUMeta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/[observationId]/index.vue?macro=true";
import { default as index6l02EWLtnVMeta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/index.vue?macro=true";
import { default as indexSz8WcgVc4kMeta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/index.vue?macro=true";
import { default as newj8CgDNWS04Meta } from "/home/niko/repo/manuscrape_nuxt/pages/projects/new.vue?macro=true";
import { default as indexlaDKmc6rnnMeta } from "/home/niko/repo/manuscrape_nuxt/pages/user/index.vue?macro=true";
import { default as newjdWiLNRbw5Meta } from "/home/niko/repo/manuscrape_nuxt/pages/user/new.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/logout.vue")
  },
  {
    name: "projects-projectId-drafts",
    path: "/projects/:projectId()/drafts",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/drafts.vue")
  },
  {
    name: "projects-projectId",
    path: "/projects/:projectId()",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/index.vue")
  },
  {
    name: "projects-projectId-observations-observationId-edit-image",
    path: "/projects/:projectId()/observations/:observationId()/edit-image",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/[observationId]/edit-image.vue")
  },
  {
    name: "projects-projectId-observations-observationId",
    path: "/projects/:projectId()/observations/:observationId()",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/[observationId]/index.vue")
  },
  {
    name: "projects-projectId-observations",
    path: "/projects/:projectId()/observations",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/[projectId]/observations/index.vue")
  },
  {
    name: "projects",
    path: "/projects",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/index.vue")
  },
  {
    name: "projects-new",
    path: "/projects/new",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/projects/new.vue")
  },
  {
    name: "user",
    path: "/user",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/user/index.vue")
  },
  {
    name: "user-new",
    path: "/user/new",
    component: () => import("/home/niko/repo/manuscrape_nuxt/pages/user/new.vue")
  }
]